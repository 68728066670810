@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.content {
  max-width: 1280px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 4.38rem 0.75rem 0 0.75rem;
}

.loader {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.logs-tb-head {

  &__title {
    display: none;

    @include tablet {
      font-size: 0.88rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      padding: 0 2rem;
      width: 100%;
    }

    @include desktop {
     padding: 0 2.5rem;
    }
  }

  &__title-name {
    font-size: 0.88rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 1rem 0;
  }

  &__sort-icon {
    width: 1rem;
    height: 1rem;
    &:hover {
      cursor: pointer;
      filter: invert(50%) sepia(95%) saturate(6000%) hue-rotate(220deg)
        brightness(90%) contrast(85%);
    }
  }
}

.node {
  @include tablet {
    min-width: 7rem;
    width: 25%;
  }
}

.msg-type {
  @include tablet {
    min-width: 4.75rem;
    width: 20%;
  }
}

.description {
  @include tablet {
    min-width: 5.75rem;
    width: 20%;
  }
}

.zone {
  @include tablet {
    min-width: 4.625rem;
    width: 30%;
  }
}
