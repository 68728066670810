@use "../../styles/partials/mixins" as *;

.mission {
  padding: 60px 12px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      flex-direction: row-reverse;
      justify-content: space-around;
    }
  }

  &__img {
    width: 2.7rem;
    margin-left: 0.5rem;
  }

  &__info {
    @include tablet {
      width: 50%;
    }
  }
}

.section-title {
  font-size: 36px;
  color: #222831;
  font-weight: bold;
  position: relative;

  &:after {
    content: "";
    width: 60px;
    height: 3px;
    background: #007bff;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
}
