@use "./mixins" as *;
@use "./fonts" as *;
@use "./variables" as *;

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "AvenirNext LT Pro", sans-serif;
}

a,
a:hover,
a:focus,
button {
  text-decoration: none;
  outline: none;
  color: unset;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h4,
h4 {
  font-size: 24px;
}

.h5,
h5 {
  font-size: 18px;
}

.h6,
h6 {
  font-size: 14px;
}
