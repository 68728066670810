@use "../../styles/partials/mixins" as *;

.product {
  padding: 80px 12px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  background-image: url("../../assets/images/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
 
  &__header {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @include tablet {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  &__img {
    margin-top: 1rem;
    width: 90%;

    @include tablet {
      width: 50%;
    }

    @include desktop {
      width: 40%;
    }
  }

  &__info {
    @include tablet {
      width: 40%;
    }
  }
}

.section-title {
  font-size: 36px;
  color: #222831;
  font-weight: bold;
  position: relative;

  &:after {
    content: "";
    width: 60px;
    height: 3px;
    background: #007bff;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
}
