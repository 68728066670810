@use "../../styles/partials/mixins" as *;

.features {
  padding: 80px 12px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);

  &__icon {
    background: #fff;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #007bff;
    transition: all 0.3s ease-in-out;
    color: #007bff;
    font-size: 24px;
    margin-bottom: 1.2rem;
  }

  &__icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid lightgray;
    padding-top: 1rem;

    &:hover .features__icon {
      background: #007bff;
      color: #fff;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
  }

  &__info {
    display: flex;
    margin-bottom: 1rem;
    gap: 1rem;
  }
}

.section-title {
  font-size: 36px;
  color: #222831;
  font-weight: bold;
  position: relative;
  
  &:after {
    content: '';
    width: 60px;
    height: 3px;
    background: #007bff;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px; 
  }
}
