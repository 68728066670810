.login-page {
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  height: 100vh;
}

.login {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}

.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form input:focus {
  background: #dbdbdb;
}

.form button {
  text-transform: uppercase;
  outline: 0;
  background: #4b6cb7;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:active {
  background: #395591;
}

.form h1 {
  font-size: 2rem;
  color: #4b6cb7;
  margin-bottom: 25px;
  display: block;
}

.form p.error {
  margin: 0 0 10px 10px;
  text-align: left;
  font-size: 0.8rem;
  color: red;
}

.form .guest-text {
  margin-top: 1rem;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.form .guest-login {
  background: transparent;
  color: #4b6cb7;
  border: 1px solid #4b6cb7;
  font-size: 0.78rem;
  font-weight: 700;
  padding: 8px 15px;
}
