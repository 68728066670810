.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0;

  &__text {
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}

.social {
  margin-bottom: 1rem;

  &__icon {
    font-size: 1.3rem;
    margin-right: 1rem;
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
  }
}

