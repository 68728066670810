@use "../../styles/partials/mixins" as *;

.header {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 2rem;

  &__title {
    font-weight: 800;
    text-align: center;
  }

  &__img {
    padding: 0;
    margin-bottom: 1rem;
    @include tablet {
      width: 60%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 0.75rem 2rem 0.75rem;
    height: 30%;
    align-items: center;
    margin-top: 1rem;
    @include tablet {
      width: 40%;
    }

    @include desktop {
      width: 512px;
      margin-left: auto;
    }
  }

  &__btn {
    width: 90%;
  }

  @include tablet {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
