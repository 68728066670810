@use "./variables" as *;

@mixin custom-tablet {
  @media screen and (max-width: $tablet-resolution) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-resolution) {
      @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-resolution) {
    @content;
  }
}