#team {
  background: #fff;
  padding: 60px 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}

#team .member {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

#team .member .member-info {
  opacity: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
}

#team .member .member-info-content {
  margin-top: 50px;
  transition: margin 0.2s;
}

#team .member .member-info-content span {
  font-family: "Open Sans", sans-serif;
}

#team .member:hover .member-info {
  background: rgba(0, 62, 128, 0.7);
  opacity: 1;
  transition: 0.4s;
}

#team .member:hover .member-info-content {
  margin-top: 0;
  transition: margin 0.4s;
}

#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}

#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #fff;
}

#team .member .social {
  margin-top: 15px;
}

#team .member .social a {
  transition: none;
  color: #fff;
}

#team .member .social a:hover {
  color: #007bff;
}

#team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.section-header h3 {
  font-size: 36px;
  color: #283d50;
  text-align: center;
  position: relative;
  margin-bottom: 2rem;
}

.section-title {
  font-size: 36px;
  color: #222831;
  font-weight: bold;
  position: relative;
  
  &:after {
    content: '';
    width: 60px;
    height: 3px;
    background: #007bff;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px; 
  }
}
