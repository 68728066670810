@use "../../styles/partials/mixins" as *;

.contact {
  padding: 80px 12px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @include desktop {
      width: 60%;
    }
  }

  &__alert {
    width: 100%;
    margin: 0 auto;

    @include desktop {
      width: 60%;
    }
  }

  &__message {
    height: 8rem;
    resize: none;
  }

  &__sentmsg {
    margin-left: 0.5rem;
  }

  &__error {
    align-self: flex-start;
    font-size: 0.8rem;
    color: red;
  }
}

.section-title {
  font-size: 36px;
  color: #222831;
  font-weight: bold;
  position: relative;

  &:after {
    content: "";
    width: 60px;
    height: 3px;
    background: #007bff;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
}
